import * as dom from '../lib/dom.js'

if (document.querySelector('.swapcard')) {

  document.querySelectorAll('.swapcard .submenu').forEach(function(submenu) {
    submenu.style.display = 'none';
  });

  // Adiciona evento de hover para mostrar e esconder os submenus
  document.querySelectorAll('.swapcard .menu-item').forEach(function(menuItem) {
    menuItem.addEventListener('mouseenter', function() {
        let submenu = menuItem.querySelector('.submenu');
        if (submenu) {
            submenu.style.display = 'block';
            submenu.style.transition = 'all 0.2s';
        }
    });

    menuItem.addEventListener('mouseleave', function() {
        let submenu = menuItem.querySelector('.submenu');
        if (submenu) {
            submenu.style.display = 'none';
            submenu.style.transition = 'all 0.2s';
        }
    });
  });

  // ASIDE MENU
  document.querySelectorAll('.swapcard .btn-aside-menu .btn-menu').forEach(function(btnMenu) {
    btnMenu.addEventListener('click', function() {
        document.querySelector('.swapcard .aside-menu').classList.toggle('active');
    });
  });

  // CLOSE ASIDE MENU
  document.querySelectorAll('.swapcard .close-menu').forEach(function(closeMenu) {
    closeMenu.addEventListener('click', function() {
        document.querySelector('.aside-menu').classList.remove('active');
    });
  });
}